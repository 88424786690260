import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import '../styles/scss/gallery.scss';

const Gallery = () => {
    const [galleryData, setGalleryData] = useState([]);

    useEffect(() => {
        fetch('https://py.testproject.work/services/api/view?status=1')
            .then(response => response.json())
            .then(data => {
                const fetchGalleryImages = data.map(service => (
                    fetch(`https://py.testproject.work/img/api/view?TableData_ID=${service.ServiceID}`)
                        .then(response => response.json())
                        .then(galleryData => ({
                            ...service,
                            images: galleryData.map((item, index) => ({
                                id: index,
                                originalFileURLs: item.originalFileURLs,
                            })),
                        }))
                        .catch(error => {
                            console.error("Error fetching gallery images for ServiceID:", service.ServiceID, error);
                            return null;
                        })
                ));
                Promise.allSettled(fetchGalleryImages)
                    .then(results => {
                        const successfulResults = results.filter(result => result.status === 'fulfilled').map(result => result.value);
                        console.log('Successful Data:', successfulResults);
                        setGalleryData(successfulResults.filter(Boolean));
                    });
            })
            .catch(error => console.error("Error fetching services:", error));
    }, []);

    return (
        <Container>
            <div className="gallery-container">
                {galleryData.map((service, index) => (
                    <Card key={index} className='mt-5 card-border1'>
                        <Card.Body className='card-bg1'>
                            <Card.Title>
                                <h1>{`${service.ServiceTypes_Name}`}</h1>
                            </Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                                Mirabeau thaï spa
                            </Card.Subtitle>
                            <div key={index} className="gallery-row">
                                <Swiper
                                    modules={[FreeMode]}
                                    slidesPerView={1}
                                    spaceBetween={0}
                                    freeMode={true}
                                    navigation={true}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false,
                                    }}
                                    className="mySwiper"
                                >
                                    {service.images.map((image, idx) => (
                                        <SwiperSlide key={idx}>
                                            <img src={image.originalFileURLs} alt={`Slide ${idx}`} className="gallery-image" />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <h3 className='mt-4'>{`${service.ServiceName}`}</h3>
                            </div>
                        </Card.Body>
                    </Card>
                ))}
            </div>
        </Container>
    );
};

export default Gallery;
