// /var/www/website2023/aaa-aa.site/website2023/s-293-spatime-dev/src/utilities/getPayZenFormToken.js

import axios from 'axios';

// Utility function to get PayZen form token
export async function getPayZenFormToken(paymentData) {
  try {

    const user_ID = localStorage.getItem('userData');

    // Add user_ID to the paymentData object
    // Include user_ID in the paymentData if not already included
    if (!paymentData.user_ID) {
      paymentData.user_ID = user_ID;
    }

    paymentData.user_ID = user_ID;

    console.log('paymentData :', paymentData);


    const response = await axios.post('https://py.testproject.work/payments/api/payzen', paymentData, {
    //const response = await axios.post('https://py.testproject.work/payments/api/payzen_test', paymentData, {
      headers: {
        'Content-Type': 'application/json',
        // Add any other necessary headers, e.g., authentication headers
      },
    });

    // Log the entire response object
    console.log('Response from PayZen API:', response);
    if (response.status !== 200) {
      console.error('Non-200 response from PayZen API:', response.data);
    }
    // Assuming the API returns the formToken directly
    const formToken = response.data.answer?.formToken;
    return formToken;
  } catch (error) {
    console.error('Error fetching PayZen form token:', error);
    // It's best to handle the error here rather than rethrowing it
    // This way, you can return a meaningful error message or value to the calling function
    return null;  // Return null or an appropriate error message
  }
}
