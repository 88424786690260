// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/pages/booking.js

import React, { useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUser, faUserGroup, faCaretDown, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { Offcanvas, Row, Col } from 'react-bootstrap';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../styles/scss/booking.scss';

import CustomSwitchWithLabel from '../components/CustomSwitchWithLabel';
import { constructDataTableArray } from '../components/DataTableBooking';
import { calculateRowSpan } from '../components/calculateRowSpan';
import OffcanvasContent from '../components/OffcanvasContent'; // Ensure the path is correct
import Spinner from 'react-bootstrap/Spinner';

import { fetchFromIndexedDB, prepareAndSaveDataToIndexedDB, printTrueInputSwitchesFromIndexedDB } from '../utilities/indexedDBUtils'; 
import { API_URLS } from '../api/api'; // Import API_URLS

const Booking = () => {
    const [services, setServices] = useState([]);
    const [checkedStates, setCheckedStates] = useState({});
    // กำหนดให้ AccordionTab สองตัวแรกเปิดโดยอัตโนมัติ
    const [activeIndex, setActiveIndex] = useState([0, 1]);
    const [aggregatedData, setAggregatedData] = useState([]); // Use this for your preprocessed data
    const [showOffcanvas, setShowOffcanvas] = useState(false); // Define showOffcanvas state variable
    // Function to close Offcanvas


    const handleCloseOffcanvas = () => {
        setShowOffcanvas(false);
    };


    // Add the deleteProduct function
    const deleteProduct = (priceID) => {
        setCheckedStates((currentStates) => {
            const newStates = { ...currentStates };
            delete newStates[priceID]; // Remove the product by priceID
            return newStates;
        });
    };

    // Modify the renderOffcanvasContent function to pass deleteProduct
    const renderOffcanvasContent = () => {
        return <OffcanvasContent checkedStates={checkedStates} getProductDetails={getProductDetails} deleteProduct={deleteProduct} />;

    };

    useEffect(() => {
        fetch(API_URLS.SERVICES)
            .then(response => response.json())
            .then(data => {
                setServices(data);
                const fetchPromises = data.map(service => {
                    if (service.ServiceID) {
                        return fetch(`${API_URLS.PRICE_VIEW}?priceConn_dataID=${service.ServiceID}`)                       
                            .then(response => response.json())
                            .then(jsonData => jsonData.prices.map(price => ({
                                ...price,
                                priceConn_dataID: service.ServiceID
                            })));
                    }
                    return Promise.resolve([]);
                });

                Promise.all(fetchPromises).then(allDataArrays => {
                    const formattedServiceData = allDataArrays.flat().reduce((acc, item) => {
                        const { ServiceTime, PriceFor, priceConn_dataID } = item;
                        if (!acc[priceConn_dataID]) {
                            acc[priceConn_dataID] = {};
                        }
                        if (!acc[priceConn_dataID][ServiceTime]) {
                            acc[priceConn_dataID][ServiceTime] = { Duo: [], Solo: [] };
                        }
                        acc[priceConn_dataID][ServiceTime][PriceFor].push(item);
                        return acc;
                    }, {});

                    //console.log('Formatted Service Data:', formattedServiceData);
                    setAggregatedData(formattedServiceData);
                })
                    .catch(error => console.error("Error processing price options:", error));
            })
            .catch(error => console.error("There was an error fetching services:", error));


    }, []); // Empty dependency array to ensure this runs once after component mounts



    // ฟังก์ชั่น fetchInitialData มีหน้าที่ดึงข้อมูลจาก IndexedDB เพื่ออัปเดตค่าใน checkedStates ให้สอดคล้องกับข้อมูลที่มีอยู่ใน IndexedDB 
    useEffect(() => {

        const fetchInitialData = async () => {
            try {
                const storedStates = await fetchFromIndexedDB('checkedStates');
                // Only set checkedStates here if storedStates is truthy
                if (storedStates) {
                    const updatedCheckedStates = Object.keys(storedStates).reduce((acc, key) => {
                        acc[key] = true; // Assuming all stored states should be set to true
                        return acc;
                    }, {});
                    setCheckedStates(updatedCheckedStates);
                }
            } catch (error) {
                console.error('Error fetching initial data from IndexedDB:', error);
            }
        };

        fetchInitialData();
    }, []);




    const handleCheckboxChange = async (priceID, currentCheckedState, serviceTime, priceFor) => {



        const isChecked = !currentCheckedState;
        const additionalInfo = {
            id: priceID,
            name: priceFor,
            serviceName: serviceTime,
            date: new Date().toLocaleDateString(),
            time: new Date().toLocaleTimeString(),
        };
        // Only update the state with the new checkbox value
        setCheckedStates(prev => ({ ...prev, [priceID]: isChecked }));

        // Prepare data for IndexedDB and save
        await prepareAndSaveDataToIndexedDB({ ...checkedStates, [priceID]: isChecked }, priceID, additionalInfo);


    };

    // Use useEffect to watch for changes in checkedStates and show Offcanvas if more than 10 switches are true
    useEffect(() => {

        const trueCount = Object.values(checkedStates).filter(value => value).length;
        if (trueCount >= 1) {
            setShowOffcanvas(true);
        }
    }, [checkedStates]); // Add checkedStates as a dependency


    // booking.js
    useEffect(() => {

        (async () => {
            try {
                const trueInputSwitches = await printTrueInputSwitchesFromIndexedDB();
                //console.log('True Input Switches from IndexedDB:', trueInputSwitches);

                // Update the component state or UI based on these true switches
                // For example, to update checkedStates:
                const updatedCheckedStates = trueInputSwitches.reduce((acc, id) => {
                    acc[id] = true; // Assuming you want to set the state to true for these IDs
                    return acc;
                }, {});

                setCheckedStates(updatedCheckedStates);

            } catch (error) {
                console.error('Failed to fetch true input switches from IndexedDB:', error);
            }
        })();


    }, []); // This array determines when the effect runs; it runs only once after the component mounts, because it's empty.

    const getProductDetails = async (priceID) => {
        const split_PriceID = priceID.split('-')[1];
        const url = `${API_URLS.PRICE_VIEW}?PriceID=${split_PriceID}`;

        //console.log(`Attempting to fetch details from: ${url}`); // Debugging log

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (!data || !data.prices || data.prices.length === 0) {
                throw new Error("Price details not found in the response");
            }

            const { PriceValue, symbol, priceConn_dataID, PriceType } = data.prices[0];
            const service = services.find(s => s.ServiceID === priceConn_dataID);
            const serviceName = service ? service.ServiceName : <Spinner animation="grow" variant="success" />;

            // Log the fetched details for debugging purposes
            // console.log(`Fetched details for PriceID: ${split_PriceID}`, {
            //     id: priceID,
            //     PriceID: split_PriceID,
            //     name: serviceName,
            //     price: PriceValue,
            //     duration: PriceType,
            //     symbol,
            //     priceConn_dataID,
            //     quantity: 1,
            // });

            return {
                id: priceID,
                PriceID: split_PriceID,
                name: serviceName,
                price: PriceValue,
                duration: PriceType,
                symbol,
                priceConn_dataID,
                quantity: 1,
            };
        } catch (error) {
            console.error("Could not fetch price details:", error);
            // Consider how you want to handle errors. For example, you might return `null` or a specific error object.
            return null;
        }
    };

    // Template for the 'ServiceTime' column
    const serviceTimeBodyTemplate = (rowData, props) => {
        const columnData = props.value || [];
        const firstInstanceIndex = columnData.findIndex(item => item.ServiceTime === rowData.ServiceTime);
        const isCurrentRowFirstInstance = props.rowIndex === firstInstanceIndex;

        if (isCurrentRowFirstInstance) {
            const rowSpan = calculateRowSpan(columnData, rowData.ServiceTime);
            return <td rowSpan={rowSpan}>{rowData.ServiceTime}</td>;
        }
        return null;
    };



    // Template for combined 'Solo' and 'Duo' prices in the same column
    const priceBodyTemplate = (rowData, props) => {
        const columnData = props.value || [];
        const firstInstanceIndex = columnData.findIndex(item => item.ServiceTime === rowData.ServiceTime);
        const isCurrentRowFirstInstance = props.rowIndex === firstInstanceIndex;

        if (isCurrentRowFirstInstance) {
            const soloPrice = columnData.find(item => item.ServiceTime === rowData.ServiceTime && item.PriceFor === 'Solo');
            const duoPrice = columnData.find(item => item.ServiceTime === rowData.ServiceTime && item.PriceFor === 'Duo');
            const rowSpan = calculateRowSpan(columnData, rowData.ServiceTime);

            return (
                <td rowSpan={rowSpan}>
                    {soloPrice && (
                        <>
                            <CustomSwitchWithLabel
                                id={`Solo-${soloPrice.PriceID}`}
                                checked={checkedStates[`Solo-${soloPrice.PriceID}`]}
                                onChange={() => handleCheckboxChange(`Solo-${soloPrice.PriceID}`, checkedStates[`Solo-${soloPrice.PriceID}`], soloPrice.ServiceTime, 'Solo')}
                                label={`${soloPrice.PriceValue} €`}
                            />
                        </>
                    )}
                    {duoPrice && (
                        <>
                            <CustomSwitchWithLabel
                                id={`Duo-${duoPrice.PriceID}`}
                                checked={checkedStates[`Duo-${duoPrice.PriceID}`]}
                                onChange={() => handleCheckboxChange(`Duo-${duoPrice.PriceID}`, checkedStates[`Duo-${duoPrice.PriceID}`], duoPrice.ServiceTime, 'Duo')}
                                label={`${duoPrice.PriceValue} €`}
                            />
                        </>

                    )}
                </td>
            );
        }
        return null;
    };



    return (

        <div className="container">

            <h2>RÉSERVER/OFFRIR</h2>
            <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {renderOffcanvasContent()}
                </Offcanvas.Body>
            </Offcanvas>

            {/* <div>
                True InputSwitches: {printTrueInputSwitches()}
            </div> */}

            <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {services.map((service, index) => (


                    <AccordionTab
                        key={service.ServiceID}
                        header={(
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                {service.ServiceName}
                                {activeIndex.includes(index) ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretLeft} />}
                            </div>
                        )}
                    >

                        <Row>
                            <Col xs={12} md={5} lg={4} xxl={6}>
                                <Card title={service.ServiceName}>
                                    <div className="description-container" dangerouslySetInnerHTML={{ __html: service.Description || 'N/A' }} />
                                </Card>
                            </Col>
                            <Col xs={12} md={7} lg={8} xxl={6} className="d-flex justify-content-end align-items-center">
                                <div className="container text-center">
                                    <DataTable value={constructDataTableArray(aggregatedData, service.ServiceID)} responsiveLayout="scroll">

                                        <Column header={<FontAwesomeIcon icon={faClock} />} body={(rowData, props) => serviceTimeBodyTemplate(rowData, { ...props, value: constructDataTableArray(aggregatedData, service.ServiceID) })} />

                                        <Column header={
                                            <>
                                                <FontAwesomeIcon icon={faUser} /> Solo
                                                <FontAwesomeIcon icon={faUserGroup} style={{ paddingLeft: '30px' }} /> Duo
                                            </>
                                        } body={(rowData, props) => priceBodyTemplate(rowData, { ...props, value: constructDataTableArray(aggregatedData, service.ServiceID) })} />
                                    </DataTable>
                                </div>
                            </Col>
                        </Row>

                    </AccordionTab>


                ))}
            </Accordion>
        </div>
    );
};

export default Booking;