import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/scss/PaymentForm.scss';
import TimelineBookingStatus from '../components/TimelineBookingStatus';
import PaymentForm from '../components/PaymentForm';
import ListGroup from 'react-bootstrap/ListGroup';
// Import translations
import fixedRateData from '../data/FixedRateConnAPI.json';
import invoiceTranslations from '../data/InvoiceDataTranslations.json';

import { Modal } from 'react-bootstrap';
import RegisterForm from '../components/register.js'; // Adjust path as necessary
import { API_URLS } from '../api/api';

const BookingPaymentFormStep = ({ profileData }) => {
  const [bookingDetails, setBookingDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [savedBookingID, setSavedBookingID] = useState("");
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [autoGeneratedPassword, setAutoGeneratedPassword] = useState('');
  const submissionResponse = useSelector(state => state.booking.submissionResponse);

  useEffect(() => {
    const checkUserLoggedIn = () => {
      return !!localStorage.getItem('token'); // Simple token-based check
    };

    if (!checkUserLoggedIn()) {
      setShowRegistrationModal(true); // Show registration modal if not logged in
    }
  }, []);

  // Handle modal visibility
  const showRegistrationModalHandler = () => {
    setShowRegistrationModal(true);
  };

  useEffect(() => {
    // Function to generate a random password
    const generateRandomPassword = () => {
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let retVal = "";
      for (let i = 0, n = charset.length; i < 8; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    };

    // Generate a new password and set it in the state
    const newGeneratedPassword = generateRandomPassword();
    setAutoGeneratedPassword(newGeneratedPassword);
    console.log("Generated Password:", newGeneratedPassword);
  }, []); // Run once on component mount

  const [formData, setFormData] = useState({
    first_name: profileData ? profileData.first_name : '',
    sur_name: profileData ? profileData.sur_name : '',
    email: profileData ? profileData.email : '',
    telephone: profileData ? profileData.telephone : '',
  });

  const [submitted, setSubmitted] = useState(false);

  // Calculate the total price including tax from booking details
  const calculateTotalPrice = useCallback(() => {
    const subTotal1 = bookingDetails.reduce((acc, detail) => acc + parseFloat(detail.BookingDetailPrice), 0);
    const taxRate = parseFloat(fixedRateData.tax) / 100;
    const taxValue = subTotal1 * taxRate;
    const subTotal = subTotal1 - taxValue;
    const total = subTotal + taxValue;
    return { subTotal1, subTotal, taxValue, total };
  }, [bookingDetails]);

  const { subTotal1, subTotal, taxValue, total } = calculateTotalPrice();

  // Remove fetchFormToken and any related logic
  // Remove generateFormToken and its usage

  // Persist BookingID to localStorage when submissionResponse changes
  useEffect(() => {
    if (submissionResponse && submissionResponse.Bookings && submissionResponse.Bookings.length > 0) {
      const bookingID = submissionResponse.Bookings[0].BookingID;
      localStorage.setItem('localBookingReduxID', bookingID);
      console.log('BookingID saved to localStorage:', bookingID);
    }
  }, [submissionResponse]);

  // On registration success
  const onRegistrationSuccess = async () => {
    const bookingID = localStorage.getItem('localBookingReduxID');
    const customerID = localStorage.getItem('userData');
    const token = localStorage.getItem('token');
    const roundedAmountInBaseUnit = Math.ceil(parseFloat(subTotal));

    const updatePayload = [{
      "BookingID": bookingID,
      "CustomerID": customerID,
      "TotalAmount": roundedAmountInBaseUnit,
      "AmountPaid": roundedAmountInBaseUnit
    }];

    console.log('updatePayload :', updatePayload);

    try {
      const response = await fetch(API_URLS.BOOKING_EDIT, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updatePayload)
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Booking updated successfully:', data);
        setShowRegistrationModal(false);
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Failed to update booking after registration:', error);
    }
  };

  // Fetch service details and prices
  const fetchServiceDetailsAndPrices = async (details) => {
    try {
      const pricesPromises = details.map((detail) =>
        fetch(`${API_URLS.PRICE_SERVICE}?PriceID=${detail.ServiceID}&status=1`)
      );
      const pricesResponses = await Promise.all(pricesPromises);
      const pricesData = await Promise.all(pricesResponses.map(res => res.json()));

      console.log('Data sent to the API:', details);
      console.log('Data received from the API:', pricesData);

      const enrichedDetails = details.map((detail, index) => {
        const { results } = pricesData[index];
        return { ...detail, ...results[0] };
      });

      setBookingDetails(enrichedDetails);
    } catch (error) {
      console.error('Failed to fetch service details and prices:', error);
    }
  };

  // Fetch booking details using BookingID
  const fetchBookingDetails = useCallback(async (bookingID) => {
    const apiUrl = `${API_URLS.BOOKING_DETAIL_VIEW}?BookingID=${bookingID}`;
    try {
      const response = await fetch(apiUrl);
      const details = await response.json();
      await fetchServiceDetailsAndPrices(details);
    } catch (error) {
      console.error('Failed to fetch booking details:', error);
    }
  }, []);

  // Retrieve the BookingID from localStorage and fetch details
  useEffect(() => {
    const savedBookingID = localStorage.getItem('localBookingReduxID');
    if (savedBookingID) {
      console.log('Retrieved BookingID from localStorage:', savedBookingID);
      setSavedBookingID(savedBookingID);
      fetchBookingDetails(savedBookingID);
    }
  }, [fetchBookingDetails]);

  const formatServiceTime = (serviceTime) => {
    const [hours, minutes] = serviceTime.split(':');
    const date = new Date();
    date.setHours(+hours);
    date.setMinutes(+minutes);
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
  };

  const formatServiceDate = (serviceDate) => {
    const date = new Date(serviceDate);
    return date.toLocaleDateString('fr-FR', { day: '2-digit', month: 'long' }).toUpperCase();
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      const token = localStorage.getItem('token');
      const user_ID = localStorage.getItem('userData');

      if (!token || !user_ID) {
        console.error('No token or user ID found');
        return;
      }

      try {
        const response = await fetch(`${API_URLS.USER_PROFILE}?user_ID=${user_ID}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setFormData({
          first_name: data.first_name || '',
          sur_name: data.sur_name || '',
          email: data.email || '',
          telephone: data.telephone || '',
        });
      } catch (error) {
        console.error('Failed to fetch profile data:', error);
      }
    };

    fetchProfileData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setIsLoading(true);

    const user_ID = localStorage.getItem('userData');

    if (!user_ID) {
      console.error('No user ID available for update');
      setIsLoading(false);
      return;
    }

    console.log('Form Data:', formData);
    const updateUrl = `${API_URLS.USER_EDIT}?user_ID=${user_ID}`;
    fetch(updateUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(formData)
    })
      .then(response => {
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then(data => console.log('Success:', data))
      .catch(error => console.error('Error:', error))
      .finally(() => setIsLoading(false));
  };

  const getLabelText = (fieldName) => {
    const labels = { first_name: 'Prénom', sur_name: 'Nom de famille', email: 'Email', telephone: 'Téléphone' };
    return labels[fieldName] || '';
  };

  return (
    <div className="schedule-step container box-main-index1">
      <TimelineBookingStatus bookedStatuses={['SERVICE', 'BOOK', 'PAYMENT']} />
      <Row>
        <Col md={8}>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              COORDONNÉES
              <Button className="custom-button" variant="primary" type="submit" form="coordonneesForm" disabled={isLoading}>
                {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <i className="pi pi-save"></i>}
              </Button>
            </Card.Header>
            <Card.Body>
              <Form id="coordonneesForm" onSubmit={handleSubmit}>
                {Object.keys(formData).map((field) => (
                  <Form.Group key={field} controlId={`form${field}`}>
                    <Form.Label>{getLabelText(field)}</Form.Label>
                    <Form.Control
                      type={field === 'email' ? 'email' : 'text'}
                      name={field}
                      value={formData[field]}
                      onChange={handleChange}
                      isInvalid={submitted && !formData[field]}
                      required
                    />
                    <Form.Control.Feedback type="invalid">Ce champ est requis.</Form.Control.Feedback>
                  </Form.Group>
                ))}
              </Form>
            </Card.Body>
          </Card>

          <Card className='mt-3 paymentForm-bg-1'>
            <Card.Header>POUR LE PAIEMENT</Card.Header>
            <Card.Body>
              <PaymentForm
                showRegistrationModal={showRegistrationModalHandler}
                savedBookingID={savedBookingID}
                customerEmail={formData.email}
                telephone={formData.telephone}
                totalAmount={total.toFixed(2)}
                subTotalAmount={subTotal.toFixed(2)}
                dateTime={new Date().toISOString()}
                firstName={formData.first_name}
                surName={formData.sur_name}
              />
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="booking-details-card">
            <Card.Header>DÉTAILS RÉSERVATION</Card.Header>
            <ListGroup className='mt-3 m-2' variant="flush">
              {bookingDetails.map((detail, index) => (
                <ListGroup.Item key={index} className="booking-list-item">
                  <div className="service-info">
                    <div className="service-name">{detail.ServiceName} {detail.PriceType} </div>
                    <span className="service-time">{formatServiceTime(detail.ServiceTime)}, {formatServiceDate(detail.ServiceDate)}</span>
                  </div>
                  <div className="service-price">{detail.BookingDetailPrice}€</div>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <div className="total-price px-2">{invoiceTranslations.FR.SUB_TOTAL}: {subTotal.toFixed(2)}€</div>
            <div className="total-price px-2">{invoiceTranslations.FR.TAX} ({fixedRateData.tax}%): {taxValue.toFixed(2)}€</div>
            <div className="total-price px-2 pb-3">{invoiceTranslations.FR.TOTAL_TRA}: {subTotal1.toFixed(2)}€</div>
          </Card>
        </Col>
      </Row>

      <Modal show={showRegistrationModal} onHide={() => setShowRegistrationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Inscrivez-vous pour continuer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RegisterForm
            onSuccess={onRegistrationSuccess}
            hidePasswordFields={true}
            autoGeneratedPassword={autoGeneratedPassword}
            initialFormData={formData}
          />
          <a className='text-forgot' href='/forgot'>Forgot Password</a>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BookingPaymentFormStep;
