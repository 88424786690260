// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/PaymentForm.js

import React, { useState, useEffect, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { getPayZenFormToken } from '../utilities/getPayZenFormToken';
import { clearAllFromIndexedDB } from '../utilities/clearAllFromIndexedDB';
import { Toast } from 'primereact/toast'; // Import Toast component

import '../styles/scss/PaymentForm.scss';

// At the top of PaymentForm.js
const STORE_NAME = 'bookings'; // Define STORE_NAME if it's not already defined elsewhere

const PaymentForm = ({ savedBookingID, customerEmail, totalAmount, subTotalAmount, dateTime, showRegistrationModal, telephone, firstName, surName }) => {

    // Initialize form data state
    const [formData, setFormData] = useState({
        cardNumber: '',
        expiryMonth: '',
        expiryYear: '',
        cvc: '',
        customerEmail: customerEmail, // Use the passed email
        customerReference: savedBookingID,
        totalAmount: totalAmount, // New
        subTotalAmount, // Add this line to receive the subTotal
        dateTime: dateTime, // dateTime
        first_name: firstName, // เพิ่ม first_name ใน formData
        sur_name: surName, // เพิ่ม sur_name ใน formData
        telephone: telephone, // เพิ่ม telephone ใน formData
    });
    // Use an effect to update the customerReference if savedBookingID changes
    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            customerReference: savedBookingID,
        }));
    }, [savedBookingID]);


    const [submitStatus, setSubmitStatus] = useState(null); // Added state for submission status
    const toast = useRef(null); // Create a ref for the Toast component

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Example function to send SMS after successful payment and booking update
    async function sendSmsNotification(paymentData) {
        try {

            const orderId = paymentData?.order?.orderId || 'Unknown Order ID';
            const amountPaid = paymentData?.subTotalAmount || 'Unknown Amount';
           
        const formattedTelephone = telephone.replace(/\s/g, ''); // Remove all spaces
        const prefixedTelephone = formattedTelephone.startsWith('0') ? '+33' + formattedTelephone.substring(1) : formattedTelephone;

            const smsData = {
                sms_recipients: [prefixedTelephone], // Assuming customerEmail is available in your component's state
                sms_text: `isaanspa Thank you for your payment! OrderID ${orderId} Amount Paid ${amountPaid} EUR`,
                sms_type: "SMS_WORLD",
                sms_sender: "Mirabeau Thai Spa", // Can be dynamically set or fetched from .env
            };

            console.log('Sending SMS notification with data:', smsData);
            const response = await fetch('https://py.testproject.work/sms/api/octopush', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(smsData),
            });

            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.error || 'Failed to send SMS notification');
            }

            console.log('SMS notification sent successfully:', responseData);
        } catch (error) {
            console.error('Error sending SMS notification:', error);
        }
    }

    const updateBookingAfterPayment = async (paymentData) => {
        const customerID = localStorage.getItem('userData');
        const bookingUpdateData = [
            {
                "BookingID": parseInt(paymentData.order.orderId),
                "AmountPaid": paymentData.amount,
                "TotalAmount": parseFloat(subTotalAmount),
                "CustomerID": customerID,
                "PaymentStatus": 9, // Example status
                "date": new Date().toISOString().split('T')[0], // Example date
                "time": new Date().toTimeString().split(' ')[0] // Example time
            }
        ];

        try {
            // Replace with your server's endpoint
            //const token = localStorage.getItem('token');
            const token = localStorage.getItem('token') || '';

            if (!token) {
                console.error('No token found. Skipping booking update.');
                return; // Skip the update operation as no valid token is present
            }

            const response = await fetch('https://py.testproject.work/booking/api/edit', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                    // Include other headers as needed, like Authorization
                },
                body: JSON.stringify(bookingUpdateData),
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const responseData = await response.json();
            console.log('Booking update response:', responseData);
        } catch (error) {
            console.error('Failed to update booking after payment:', error);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitStatus('submitting'); // Indicate that submission is in progress

        const token = localStorage.getItem('token') || '';

        if (!token) {
            // Directly show the registration modal if not logged in
            showRegistrationModal();
            // Set the button to show 'Failed - Try Again' since the user is not logged in
            setSubmitStatus('failure');
            return; // Prevent further processing of the form submission
        }

        // Check if all required form data is filled formData.customerEmail
        if (!formData.cardNumber || !formData.expiryMonth || !formData.expiryYear || !formData.cvc || !formData.customerEmail) {
            // Show a toast message instead of submitting the form
            toast.current.show({
                severity: 'error',
                summary: 'Form Validation Failed',
                detail: 'All fields are required, please complete the form before submitting.',
                life: 5000
            });
            setSubmitStatus('failure'); // Ensure the button does not show 'Submitting...'
            return;
        }

        setSubmitStatus('submitting'); // Indicate that submission is in progress only if logged in

        const roundedAmountInBaseUnit = Math.ceil(parseFloat(totalAmount));
        //console.log('Rounded Amount:', roundedAmountInBaseUnit);



        const paymentData = {
            amount: roundedAmountInBaseUnit,
            currency: "EUR",
            order: {
                orderId: formData.customerReference, // Use savedBookingID as the order ID
            },
            card: {
                number: formData.cardNumber,
                expiryMonth: formData.expiryMonth,
                expiryYear: formData.expiryYear,
                cvc: formData.cvc
            },
            customer: {
                reference: formData.customerReference,
                email: formData.customerEmail,
                first_name: firstName, // Include firstName
                sur_name: surName,     // Include surName
                telephone: telephone,  // Ensure telephone is correctly received and used
            },
            transactionOptions: {
                cardOptions: {
                    paymentProductList: ["VISA"] // Assuming VISA is the only option; adjust as needed
                }
            }
        };

        // Log the paymentData sent to the API
        console.log('Payment Data:', paymentData);

        // สมมติว่าคุณมีเอ็นด์พอยต์ด้านเซิร์ฟเวอร์ที่ปลอดภัยเพื่อจัดการกระบวนการการชำระเงิน
        try {
            const formToken = await getPayZenFormToken(paymentData);
            if (formToken) {
                console.log('Form Token:', formToken);
                setSubmitStatus('success'); // Indicate a successful submission
                updateBookingAfterPayment(paymentData);

                // Call clearAllFromIndexedDB after a successful submission
                await clearAllFromIndexedDB(STORE_NAME);

                // Call sendSmsNotification to send an SMS notification
                await sendSmsNotification(paymentData);

                window.location = '/payment/invoice';

            } else {
                console.error('Form token was not returned from the API.');
                setSubmitStatus('failure'); // Indicate a failed submission
            }
        } catch (error) {
            console.error('Payment processing error:', error);
            setSubmitStatus('failure'); // Indicate a failed submission
        }
    };



    return (
        <div className="payment-form">
            <Toast ref={toast} />
            <div className="payment-logos">
                <img src="https://afaa.website/s/c9cae2.svg" alt="Visa Logo" className="payment-logo" />
                <img src="https://afaa.website/s/0efefb.webp" alt="PayZen Logo" className="payment-logo" />
            </div>

            <Form onSubmit={handleSubmit} noValidate>

                <Form.Group controlId="cardNumber">
                    <Form.Label>Card Number</Form.Label>
                    <Form.Control type="text" name="cardNumber" onChange={handleChange} required />
                </Form.Group>


                <div className="row mt-3 mb-4">

                    <Form.Group controlId="expiryMonth" className="col">
                        <Form.Label>Expiration Month</Form.Label>
                        <Form.Control type="text" name="expiryMonth" onChange={handleChange} required />
                    </Form.Group>

                    <Form.Group controlId="expiryYear" className="col">
                        <Form.Label>Expiration Year</Form.Label>
                        <Form.Control type="text" name="expiryYear" onChange={handleChange} required />
                    </Form.Group>

                    <Form.Group controlId="cvc" className="col">
                        <Form.Label>CVC</Form.Label>
                        <Form.Control type="text" name="cvc" onChange={handleChange} required />
                    </Form.Group>

                </div>



                <Form.Group controlId="customerEmail">
                    <Form.Label>Customer Email</Form.Label>
                    <Form.Control type="email" name="customerEmail" onChange={handleChange} required />
                </Form.Group>

                <Form.Group controlId="customerReference">
                    <Form.Label>Customer Reference ID</Form.Label>
                    <Form.Control type="text" name="customerReference" value={formData.customerReference} onChange={handleChange} required disabled />
                </Form.Group>


                <Button
                    className={`custom-button mt-3 ${submitStatus === 'failure' ? 'p-button-danger' : ''}`}
                    type="submit"
                    disabled={submitStatus === 'submitting'}
                >
                    {submitStatus === 'submitting' ? 'Submitting...' :
                        submitStatus === 'failure' ? 'Failed - Try Again' : 'Submit Payment'}
                </Button>
            </Form>

        </div>
    );
};

export default PaymentForm;
